import { useEffect } from 'react';

const JoinRedirect = () => {
  useEffect(() => {
    // Function to track the click event before redirecting
    trackClickEvent().then(() => {
      // Replace this URL with your actual App Store URL
      window.location.href = 'https://apps.apple.com/us/app/roll-photo-journal/id6472741341';
    });
  }, []);

  // Placeholder content while redirecting/loading
  return <div>Redirecting to the App Store...</div>;
};

const trackClickEvent = () => {
    return new Promise((resolve) => {
      window.gtag('event', 'join_click', {
        'event_category': 'Join Redirect',
        'event_label': 'App Store Redirect',
        'value': '1'
      });
      setTimeout(resolve, 500);
    });
  };
  

export default JoinRedirect;
