import React from "react";
import Pic from "./BoxPic.js";
import '../App.css'


function Layout({ imageUrls }) {


    return (
        <div style={styles.pic_container}>
            {Array.isArray(imageUrls) && imageUrls.map((url, index) => (
            <Pic key={index} url={url} 
            />
             ))}
        </div>
    )
}

const styles = {
    // pic_container : {
    //     margin: 0,
    //     padding: 0,
    //     width: '100.1vw',
    //     position: 'absolute',
    //     left: '50%',
    //     transform: 'translateX(-50%)',
    //     display: 'grid',
    //     gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
    //     gridAutoRows: 'auto',
    //     justifyContent: 'center'
    // }
    pic_container: {
        margin: 0,
        padding: 0,
        width: '100.1vw',
        gridAutoRows: 'auto',
        display: 'grid',
        columnGap: '0px', 

    
    }
    
}
export default Layout;