import './LandingPage.css';
import { Helmet } from 'react-helmet';

function LandingPage() {

    const previewIMG = "./RollPhoto.png";


    return <>
    <Helmet>
        <title>Roll: Photo Journal</title>
        <meta property="og:image" content={previewIMG} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:title" content="Roll: Photo Journal" />
        <meta property="og:description" content="Your personal photo journal, made to overshare." />
        <meta property="og:url" content="https://rollai.app/" />
    </Helmet>
    <div className="landing-page-container">
            <div className="content">
                <div className='title'>Roll</div>
                <div className='slogan'>made to overshare</div>
                <img src="/proto.png" className="center" alt="prototype of app"
                    style={{  
                        height: '400px',  
                        paddingTop: '1rem'
                    }}>
                </img>
                <a href="https://apps.apple.com/us/app/roll-photo-journal/id6472741341" target="_blank" rel="noopener noreferrer">
                    <img src="/downloadButton.png" alt="Download on the App Store" className="center" style={{ paddingTop: '2rem', width: '11rem', height: 'auto'  }} />
                </a>
            </div>
            <footer className="footer-links">
                <a href="http://tinyurl.com/roll-correct-policy" target="_blank" rel="noopener noreferrer">Privacy</a>
                <a href="http://tinyurl.com/roll-correct-terms" target="_blank" rel="noopener noreferrer">Terms</a>
                <a href="mailto:zsabbagh@candidsocial.app" target="_blank" rel="noopener noreferrer">Contact</a>
            </footer>
        </div>
    </>
}
export default LandingPage