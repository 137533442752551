
// import React from 'react';
// import './DownloadAppPopup.css'; 

// const DownloadAppPopup = () => {
//     const appStoreLink = "https://apps.apple.com/us/app/roll-photo-journal/id6472741341"; 

//     return (
//         <a href={appStoreLink} target="_blank" rel="noopener noreferrer" className="download-app-popup-link">
//             <div className="download-app-popup">
//                 <p>Get the best experience in our app.</p>
//                 <img src="/downloadBlack.png" alt="Download on the App Store" className="center" style={{ width: '9rem', height: 'auto', paddingBottom: '5px' }} />
//             </div>
//         </a>
//     );
// };

// export default DownloadAppPopup;

import React from 'react';
import './DownloadAppPopup.css'; 

const DownloadAppPopup = ({ onOpenInApp, appStoreLink, showOpenInApp }) => {
    return (
        <div className="download-app-popup">
            <p>Get the best experience in our app.</p>
            {showOpenInApp ? (
                <button onClick={onOpenInApp} className="open-in-app-button" style={{ paddingBottom: '100px' }}>
                    Open in App
                </button>
            ) : (
                <a href={appStoreLink} target="_blank" rel="noopener noreferrer" className="download-app-popup-link">
                    <img src="/downloadBlack.png" alt="Download on the App Store" className="center" style={{ width: '9rem', height: 'auto', paddingBottom: '5px' }} />
                </a>
            )}
        </div>
    );
};

export default DownloadAppPopup;

