import React from "react";

function Pic({ url }) {
    return (
        <div style={{ ...styles.pic }}>
            <img src={url} alt="" style={styles.image} draggable="true" />
        </div>
    )
}

const styles = {
    pic: {
        margin: '0px 0px',
        padding: 0,
        backgroundColor: 'white',
        display: 'inline-block',
        breakInside: 'avoid',
        backgroundSize: 'cover',  
        backgroundPosition: 'center',
        marginBottom: '0px',
        pageBreakInside: 'avoid', // For non-flexible layouts
        overflow: 'hidden'
    },
    image: {
        display: 'block',
        maxWidth: '100%', // Ensures the image doesn't exceed the width of its container
        height: 'auto',  // Keeps the aspect ratio of the image
    },
}

export default Pic;
