
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DocumentImages from './DocumentImages.js'
import LandingPage from './LandingPage.js';
import JoinRedirect from './JoinRedirect.js';


function App() {


  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/join" element={<JoinRedirect />} />
        <Route path="/:documentId" element={<DocumentImages />} />
        {/* Other routes */}
      </Routes>
    </Router>
    </>

    
  );
}

export default App;
