import './App.css';
import Layout from './components/PicLayout.js';
import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import DownloadAppPopup from './DownloadAppPopup.js';


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    projectId: process.env.REACT_APP_PROJECT_ID,
  };
  
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  
  const useFetchImageUrls = () => {
    const { documentId } = useParams();
    const [imageUrls, setImageUrls] = useState([]);
    const [isError, setIsError] = useState(false); 
  
    useEffect(() => {
      const fetchImages = async () => {
        const docRef = doc(db, 'posts', documentId); // Get a reference to the document
        const docSnap = await getDoc(docRef); // Fetch the document
  
        if (docSnap.exists()) {
          const images = docSnap.data().imagesDictionary;
          if (images && Array.isArray(images)) {
            const urls = images.filter(image => image.imageURL && image.visibility === 'public')
                              .map(image => image.imageURL);
            setImageUrls(urls);
          }
        } else {
            setIsError(true); // Handles the case where the document does not exist
        }
      };
  
      fetchImages();
    }, [documentId]);
  
    return { imageUrls, isError };
  };

  function DocumentImages() {
    const { imageUrls, isError } = useFetchImageUrls();
    const totalImages = Math.min(imageUrls.length, 100);

    const [showPopup, setShowPopup] = useState(false);
    const [showOpenInAppOption, setShowOpenInAppOption] = useState(true); // Adjust based on your logic
    const appStoreLink = "https://apps.apple.com/us/app/roll-photo-journal/id6472741341";
    const path = window.location.pathname;
    const documentId = path.split('/')[1];

    useEffect(() => {
      var isiOS = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isiOS && documentId) {
          setShowOpenInAppOption(true);
          setShowPopup(true);
      } else {
        setShowPopup(true);
        setShowOpenInAppOption(false);
      }
  }, [documentId]);

  const handleOpenInApp = () => {
    let appOpened = false;
    
    // Function to attempt opening the app
    const openApp = () => {
        window.location.href = `Roll://album?PostID=${documentId}&UserID=web&showComments=false`;
    };
    
    // Function to redirect to the App Store
    const redirectToStore = () => {
        if (!appOpened) {
            window.location.href = appStoreLink;
        }
    };

    // Event listener for visibility change
    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            appOpened = true;
        }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    // Open the app
    openApp();

    // Set a timeout to redirect to the App Store
    setTimeout(redirectToStore, 2000); // Adjust the timeout as needed

    // Clean up the event listener when the component is unmounted or the user navigates away
    return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    };
};



    if (isError) {
        return <div style={{ padding: '30vw', textAlign: 'center', fontSize: '20px', color: 'white' }}>This photo album doesn't exist :(</div>; // Render error message
      }
  
    const defaultOgImage = "./RollPhoto.png"; //placeholder
    const ogImageUrl = imageUrls.length > 0 ? imageUrls[0] : defaultOgImage;
    return (
        <><Helmet>
            <title>Check Out My Roll!</title>
            <meta property="og:image" content={ogImageUrl} />
            <meta property="og:url" content={`https://rollai.app/${documentId}`} />
            <meta property="og:image:type" content="image/jpeg" />
            {/* You can add more meta tags here if needed */}
        </Helmet>
        <div>
          <Layout imageUrls={imageUrls.slice(0, totalImages)} />
          {showPopup && (
                <DownloadAppPopup 
                    onOpenInApp={handleOpenInApp} 
                    appStoreLink={appStoreLink} 
                    showOpenInApp={showOpenInAppOption} 
                />
            )}
        </div></>
    );
  }
  
  export default DocumentImages;
  